import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { makeLoadContent } from '../../../../main/usecases/contents/load-content-factory'
import { makeContentsbyParams, makeLoadSkill, makeSeasons } from '../../../../main/usecases/contents/load-skill-factory'
import { Box, Container } from '../../../components/ui/components'
import ContentMedia from '../../../components/ui/components/content-media/content-media'
import { Col, Row } from '../../../components/ui/layout'
import { useAlertBox } from '../../../contexts'
import { customTheme } from '../../../styles/theme'
import { ContentItem } from '../skill-page/skill-season'
import { BottomContainer } from './bottom-content'

const SeasonPage = () => {
  const [content, setContent] = useState({})
  const [episodes, setEpisodes] = useState([])
  const [seasons, setSeasons] = useState([])
  const [season, setSeason] = useState([])
  const [idSkill, setIdSkill] = useState([])
  const [nextContentsMeta, setNextContentsMeta] = useState({})
  const alertBox = useAlertBox()
  const history = useHistory()
  const [nextContent, setNextContent] = useState(null)

  useEffect(() => {
    try {
      (async () => {
        const content = await makeLoadContent(history.location.pathname.split('/')[3]).load()
        setContent(content)

        const skill = await makeLoadSkill(content?.folder?.id).load()
        setIdSkill(skill.id)
        const seasonsData = await makeSeasons().load()
        setSeasons(seasonsData['hydra:member'])

        const promisses = []
        seasonsData['hydra:member'].forEach(item => {
          promisses.push(
            makeContentsbyParams(skill.slug, item.slug).load()
          )
        })

        let data = []
        Promise.all(promisses).then(resp => {
          resp.forEach(item => {
            data = [...data, ...item['hydra:member']]
          })
          setEpisodes(data)

          const currentSeason = data.filter(i => i.season.slug === content.season.slug)
          setSeason(currentSeason)
          const currentPosition = currentSeason.findIndex(i => i.id === content.id)
          const nextContentIndex = []
          for (let i = currentPosition; i < currentSeason.length - 1; i++) {
            nextContentIndex.push(i + 1)
          }
          setNextContentsMeta({
            list: currentSeason,
            nextIndex: nextContentIndex
          })
          setNextContent(currentSeason && !!nextContentIndex.length && currentSeason[nextContentIndex[0]])
        })
      })()
    } catch (error) {
      alertBox(error.message)
    }
  }, [])

  const handleNextContent = () => {
    if (nextContent) {
      const redirectTo = `/habilidades/${nextContent.folder.id}/${nextContent.slug}`
      history.replace(redirectTo)
    } else {
      const redirectTo = `/habilidades/${idSkill}`
      history.replace(redirectTo)
    }
  }

  return (!!content &&
    <Container>
      <Box mt={40} />
      <Row>
        <Col sm="9">
          <ContentMedia
            content={content}
            onHandleFinish={handleNextContent}
          />
        </Col>
        <Col sm="3" h="100%">
          <LeftBar>
            {nextContentsMeta?.nextIndex?.map((item, index) => (
              <ContentItem
                key={index}
                content={nextContentsMeta?.list[item]}
              />
            ))}
          </LeftBar>
        </Col>
      </Row>
      <BottomContainer
        content={content}
        seasons={seasons}
        episodes={episodes}
        season={season}
      />
    </Container>
  )
}

export default SeasonPage

const LeftBar = styled.div`
  margin-bottom: 0;
  overflow-y: scroll;
  aspect-ratio: 265/469;
  padding-right: 2px;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f0ee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${customTheme.colors.purple};
    border-radius: 5px;
  }
`
