import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { makeComments } from '../../../../main/usecases/contents/comments-factory'
import {
  Col,
  Comments,
  Row
} from '../../../components/ui/layout'
import EvaluationContent from '../../../components/ui/layout/evaluation-content/evaluation-content'
import { customTheme } from '../../../styles/theme'
import { FiCheckCircle } from 'react-icons/fi'
import { FaRegBookmark } from 'react-icons/fa'
import { SeasonProgressItem } from './season-progress-item'
import { Link, useHistory } from 'react-router-dom'
import { makeNetworkFilters, makeNetworkInvites, makeNetworks } from '../../../../main/usecases/network/network-factory'
import { getUser } from '../../../utils/storage/user'
import NetworkImage from '../../../assets/images/networkImage.png'
import { useContentHandler } from '../../../contexts/content-handler'
import { tagsNotes } from '../../../helpers/enums/enums'
import { makeNotes, makeNotesItem } from '../../../../main/usecases/notes/notes-factory'
import NoteItem from '../../notas/nota-item'
import { getFullTime } from '../../../helpers/date-helper/date-helper'
import { useAlertBox } from '../../../contexts'
import { makeRatingContent } from '../../../../main/usecases/evaluations/rating-factory'
import { makeFavoriteContent, makeLikeContent } from '../../../../main/usecases/evaluations/favorite-factory'
import { Flex } from '../../../components/ui/components'
import { useTranslation } from 'react-i18next'
import TransLateHook from '../skill-page/translate-hook'

export const BottomContainer = ({ content, seasons, episodes, season }) => {
  const { currentTimeVideo, tabShow, setTabShow, displayNote, setDisplayNote } = useContentHandler()
  const [networkFilters, setNetworkFilters] = useState([])
  const [networkFiltersSelected, setNetworkFiltersSelected] = useState([])
  const [networkStatus, setNetworkStatus] = useState(null)
  const [save, setSave] = useState(null)

  const [noteValues, setNoteValues] = useState({
    title: '',
    description: ''
  })
  const [etiquetasSelected, setEtiquetasSelected] = useState(null)

  const [notes, setNotes] = useState([])
  const [atualNote, setAtualNote] = useState({})
  const atualTagNote = tagsNotes.find(i => i.value === atualNote?.tag)
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { user } = getUser()
  const { alertBox } = useAlertBox()
  const TRANSLATE_TITLE = TransLateHook()

  const TRANSLATE = {
  //  Metodologia: `${t('watch_content.watch14')}`
    Mente: `${t('description_skills.title1')}`,
    Modelagem: `${t('description_skills.title2')}`,
    Metodologia: `${t('description_skills.title3')}`
  }

  const language = i18n.language

  const sendNetwork = async () => {
    try {
      await makeNetworkInvites().post({
        content: `/v1/contents/${content.id}`,
        companyJobRoles: networkFiltersSelected,
        ageRanges: []
      })
      setNetworkStatus({
        isInviter: true,
        status: 'pending'
      })
    } catch (error) {
      alertBox(error.message)
      console.error(error)
    }
  }

  const sendNote = async () => {
    try {
      const note = await makeNotes().post({
        timestamp: currentTimeVideo / 1000,
        title: noteValues.title,
        description: noteValues.description,
        tag: etiquetasSelected,
        content: '/v1/contents/' + content.id
      })

      alertBox('Nota salva com sucesso')
      setAtualNote({})
      setNotes([
        {
          ...note,
          content: content
        },
        ...notes
      ])
      setNoteValues({
        title: '',
        description: ''
      })
      setTabShow('notes-list')
    } catch (error) {
      alertBox(error.message)
      console.error(error)
    }
  }

  const deleteNote = async (id) => {
    try {
      await makeNotesItem(id).delete()
      setNotes(notes.filter(item => item['@id'] !== `/v1/notes/${id}`))
      if (atualNote['@id'] === `/v1/notes/${id}`) {
        setAtualNote({})
      }

      alertBox('Nota excluída')
    } catch (error) {
      alertBox(error.message)
      console.error('NotesInterna.handleDelete', error)
    }
  }

  const updateNote = async () => {
    try {
      console.warn('sending', atualNote)
    } catch (error) {
      console.error(error)
    }
  }

  const handleNetworkFilter = (item) => {
    if (networkFiltersSelected.includes(item)) {
      setNetworkFiltersSelected(prev => (prev.filter(i => i !== item)))
    } else {
      setNetworkFiltersSelected(prev => ([...prev, item]))
    }
  }

  const handleFavoriteContent = async () => {
    try {
      setSave(prev => !prev)
      await makeFavoriteContent().post({
        content: `/v1/contents/${content.id}`
      })
    } catch (error) {
      console.error('BottomContainer.handleFavoriteContent', error)
    }
  }

  useEffect(async () => {
    try {
      if ('id' in content) {
        const filters = await makeNetworkFilters(content.id).loadFull()
        setNetworkFilters(filters.companyJobRoles)

        const resNotes = await makeNotes().load()
        setNotes(resNotes['hydra:member'].filter(item => item?.content?.id === content.id))
        setAtualNote(resNotes['hydra:member'].find(item => item['@id'] === displayNote))

        setSave(content?.currentContentUserCollection?.favorite)

        const statusNetwork = await makeNetworks().load({
          content: content.id
        })

        if (statusNetwork.list.length) {
          setNetworkStatus({
            isInviter: statusNetwork.list[0].inviter?.id === user.id,
            status: statusNetwork.list[0].status
          })
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [content, displayNote])

  useEffect(() => {
    setTabShow('description')
  }, [])

  return (
    <>
      <Row mt={-10}>
        <Col sm="9">
          <Tabs>
            <button className={tabShow === 'description' && 'active'} onClick={() => setTabShow('description')}>{t('watch_content.watch08')}</button>
            <button className={tabShow === 'network' && 'active'} onClick={() => setTabShow('network')}>{t('watch_content.watch09')}</button>
            <button
              className={(tabShow === 'notes' || tabShow === 'notes-list') && 'active'}
              onClick={() => {
                setTabShow('notes-list')
                setAtualNote({})
              }}
            >
              {tabShow === 'notes' ? `${t('watch_content.watch10')}` : `${t('watch_content.watch11')}`}
            </button>
          </Tabs>
        </Col>
      </Row>

      {tabShow === 'description' &&
        <StyledRow>
          <Col sm="9">
            <ContentBoxStyled>
              <Flex justifyContent="space-between" mb={35}>
                <Season>
                  {TRANSLATE[content?.season?.title] ? TRANSLATE[content?.season?.title] : content?.season?.title}
                  <AtualIndex>
                    <span className="first-child">{season.findIndex(item => item.slug === content.slug) + 1}</span>
                    <span className="last-child">/{season?.length}</span>
                  </AtualIndex>
                </Season>
                {save !== null &&
                  <SaveButton onClick={() => handleFavoriteContent()}>
                    {save
                      ? <><FiCheckCircle /> {t('watch_content.watch12')}</>
                      : <><FaRegBookmark />{t('watch_content.watch13')}</>}
                  </SaveButton>}
              </Flex>
              <ContentTitle>{TRANSLATE_TITLE[content?.title] ? TRANSLATE_TITLE[content?.title] : content?.title}</ContentTitle>

              <ContentBox dangerouslySetInnerHTML={{
                __html: content.content
                  ? content.content
                // : TRANSLATE_SUMMARY[content.summary] ? TRANSLATE_SUMMARY[content.summary] : content.summary

                  : language === 'es-ES' ? null : content.summary
              }}
              />
              <EvaluationContent
                contentId={content.id}
                favoriteContent={makeLikeContent()}
                ratingContent={makeRatingContent()}
                collection={content.currentContentUserCollection}
                likes={content.likes}
              />
            </ContentBoxStyled>

            <Comments
              contentId={content.id}
              comments={makeComments(history.location.pathname.split('/')[history.location.pathname.split('/').length - 1])}
            />

          </Col>
          <Col sm="3">
            <SeasonTitle>{t('watch_content.watch15')}:</SeasonTitle>
            <SeasonProgress>
              {seasons.map((item, index) => (
                <SeasonProgressItem
                  key={index}
                  className="progress-item"
                  content={item}
                  episodes={episodes}
                  current={content?.season?.slug === item.slug}
                />
              ))}
            </SeasonProgress>
          </Col>
        </StyledRow>}

      {tabShow === 'network' &&
        <StyledRow>
          <Col sm="9">
            <ContentTitle>{t('watch_content.watch09')}</ContentTitle>
            <NetworkBox>
              {networkStatus === null &&
                <>
                  {networkFilters.length
                    ? <h3>{t('watch_content.watch19')}:</h3>
                    : <h3 style={{ textAlign: 'center' }}>{t('watch_content.watch20')}.</h3>}

                  {networkFilters.length &&
                    <>
                      <NetworkQuestion>
                        <h4>{t('watch_content.watch21')}</h4>
                        {networkFilters.map((item, index) => (
                          <button
                            key={index}
                            onClick={() => handleNetworkFilter(item)}
                            className={networkFiltersSelected.includes(item) && 'active'}
                          >
                            {item}
                          </button>
                        ))}
                      </NetworkQuestion>
                      <SendButton onClick={sendNetwork}>{t('watch_content.watch22')}</SendButton>
                    </>}
                </>}

              {networkStatus?.isInviter && networkStatus?.status === 'pending' &&
                <SuccessNetwork>
                  <img src={NetworkImage} alt="" />
                  <SuccessText>
                    <h1>{t('watch_content.watch23')}</h1>
                    <p>{t('watch_content.watch24')}</p>
                    <ButtonBox>
                      <Link to="/networks" className="primary">{t('watch_content.watch25')}</Link>
                    </ButtonBox>
                  </SuccessText>
                </SuccessNetwork>}

              {!networkStatus?.isInviter && networkStatus?.status === 'pending' &&
                <SuccessNetwork>
                  <img src={NetworkImage} alt="" />
                  <SuccessText>
                    <h1>{t('watch_content.watch26')}</h1>
                    <ButtonBox>
                      <Link to="/networks" className="primary">{t('watch_content.watch25')}</Link>
                    </ButtonBox>
                  </SuccessText>
                </SuccessNetwork>}

              {!networkStatus?.isInviter && networkStatus?.status === 'accepted' &&
                <SuccessNetwork>
                  <img src={NetworkImage} alt="" />
                  <SuccessText>
                    <h1>{t('watch_content.watch27')}</h1>
                    <ButtonBox>
                      <Link to="/networks" className="primary">{t('watch_content.watch25')}</Link>
                    </ButtonBox>
                  </SuccessText>
                </SuccessNetwork>}
            </NetworkBox>
          </Col>
        </StyledRow>}

      {tabShow === 'notes' &&
        <StyledRow>
          <Col sm="9">
            <ContentTitle>{t('watch_content.watch28')}</ContentTitle>
            <NetworkBox>
              <SelectedTime>{t('watch_content.watch29')}: {getFullTime(currentTimeVideo, 'min')} min</SelectedTime>
              <Etiqueta>
                <h2>{t('watch_content.watch30')}:</h2>

                {tagsNotes.map((item, index) => (
                  <button
                    key={item.slug}
                    onClick={() => setEtiquetasSelected(item.value)}
                    className={etiquetasSelected === item.value && 'active'}
                    style={{
                      background: item.backgroundColor,
                      color: item.fontColor
                    }}
                  >{item.label}
                  </button>
                ))}
              </Etiqueta>

              <Title
                value={noteValues.title}
                placeholder={t('watch_content.watch31')}
                onChange={e => setNoteValues({ ...noteValues, title: e.target.value })}
              />
              <Description
                value={noteValues.description}
                placeholder={t('watch_content.watch32')}
                onChange={e => setNoteValues({ ...noteValues, description: e.target.value })}
              />

              <SendButton
                onClick={sendNote}
                disabled={!noteValues.title || !noteValues.description || !etiquetasSelected}
                // disabled
              >{t('watch_content.watch33')}
              </SendButton>
            </NetworkBox>
          </Col>
        </StyledRow>}

      {tabShow === 'notes-list' &&
        <StyledRow>
          <Col sm="4">
            <ContentTitle>{t('watch_content.watch34')}</ContentTitle>
            <NoteListBox>
              {notes.map((item, index) => (
                <NoteItem
                  key={index}
                  index={index + 1}
                  content={item}
                  handleClick={() => setDisplayNote(item['@id'])}
                  handleDelete={deleteNote}
                />
              ))}
            </NoteListBox>
          </Col>
          <Col sm="8">
            <NoteBox>
              {atualNote && 'title' in atualNote
                ? (
                  <>
                    <Tag
                      style={{
                        background: atualTagNote?.backgroundColor,
                        color: atualTagNote?.fontColor
                      }}
                    >{atualTagNote?.label}
                    </Tag>
                    <Title
                      value={atualNote?.title}
                      placeholder={t('watch_content.watch31')}
                      onChange={e => setAtualNote({ ...atualNote, title: e.target.value })}
                    />
                    <Description
                      value={atualNote?.description}
                      placeholder={t('watch_content.watch32')}
                      onChange={e => setAtualNote({ ...atualNote, description: e.target.value })}
                    />

                    <SendButton onClick={updateNote}>{t('watch_content.watch35')}</SendButton>
                  </>
                  )
                : <Message>{t('watch_content.watch36')}</Message>}

            </NoteBox>
          </Col>
        </StyledRow>}
    </>
  )
}

BottomContainer.propTypes = {
  content: PropTypes.any.isRequired,
  seasons: PropTypes.any.isRequired,
  episodes: PropTypes.any.isRequired,
  season: PropTypes.any.isRequired
}

const ContentBoxStyled = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #D8D8D8;
`

const ContentTitle = styled.h1`
  color: ${customTheme.colors.purple};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  margin-bottom: 20px;
`

const ContentBox = styled.div`
  color: #828282;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
`

const StyledRow = styled(Row)`
  align-items: flex-start;
`

const SeasonTitle = styled.h3`
  font-style: normal;
  color: ${customTheme.colors.purple};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const SeasonProgress = styled.div`
  .progress-item {
    position: relative;
    width: 90%;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #D8D8D8;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 5px);
      right: -20px;
    }

    &:not(:last-child):after {
      content: '';
      width: 2px;
      height: 133%;
      background: #D8D8D8;
      position: absolute;
      top: calc(50% + 10px);
      right: -16.5px;
    }
  }
`

const Season = styled.span`
  color: ${customTheme.colors.purple};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin: 0;
`

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #D8D8D8;

  button {
    height: 40px;
    width: 100%;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
      border-right: 1px solid #D8D8D8;
    }

    &.active {
      border-bottom: 4px solid #203B4C;
    }
  }
`

const NetworkBox = styled.div`
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12));
  border-radius: 6px;
  padding: 30px;
  margin: 40px 0 0;
  background: #f6f4f3;

  h3 {
    color: ${customTheme.colors.purple};
    margin-bottom: 20px;
  }
`

const NetworkQuestion = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0;
  flex-wrap: wrap;

  h4 {
    font-size: 16px;
    color: ${customTheme.colors.purple};
    width: 100%;
  }

  button {
    border: none;
    background: #d9d9d9;
    border-radius: 26px;   
    padding: 8px 20px; 
    margin: 5px 10px 10px 0;
    cursor: pointer;

    &.active {
      background: ${customTheme.colors.purple};
      color: #fff;
    }
  }
`

const SendButton = styled.button`
  height: 40px;
  background: ${customTheme.colors.purple};
  border-radius: 6px;
  font-size: 14px;
  padding: 0 20px;
  color: #fff;
  border: none;
  margin-top: 10px;
  cursor: pointer;

  &:disabled {
    opacity: .3;
    cursor: not-allowed;
  }
`

const SuccessNetwork = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 40%;
  }
`

const SuccessText = styled.div`
  width: 50%;

  h1 {
    color: ${customTheme.colors.purple};
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 120%;
    margin-bottom: 20px;
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: ${customTheme.colors.purple};
    margin-bottom: 25px;
    line-height: 26px;
  }
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    border: none;
    height: 35px;
    background: #E3E4E4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 45%;
    font-weight: bold;
    text-decoration: none;

    &.primary {
      background: ${customTheme.colors.purple};
      color: #fff;
    }
  }
`

const Etiqueta = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: ${customTheme.colors.purple};
    margin-right: 10px;
  }

  button {
    padding: 0 30px;
    height: 32px;
    border-radius: 26px;
    border: none;
    margin: 0 10px;
    cursor: pointer;

    &.active {
      border: 2px solid;
    }
  }
`

const Title = styled.input`
  min-height: 40px;
  border-radius: 6px;
  background: #fff;
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: ${customTheme.colors.purple};
  text-align: left;
  width: 100%;
`

const Description = styled.textarea`
  border-radius: 6px;
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  font-size: 14px;
  border: none;
  width: 100%;
  min-height: 150px;
`

const Tag = styled.span`
  border-radius: 26px;
  font-size: 12px;
  height: 32px;
  border: none;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-weight: bold;
  color: #fff;
  margin-right: 15px;
  width: fit-content;
`

const NoteBox = styled.div`
  padding-top: 15px;
`

const NoteListBox = styled.div`
  overflow-y: scroll;
  height: 400px;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #dfdfdf; 
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${customTheme.colors.purple};
    border-radius: 4px;
  }
`

const SelectedTime = styled.h4`
  color: ${customTheme.colors.purple};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
`

const Message = styled.h3`
  color: ${customTheme.colors.purple};
  text-align: center;
  margin: 40px auto 0;
  font-size: 16px;
`

const AtualIndex = styled.div`
  font-size: 14px;
  margin: -5px 10px 0;

  .first-child {
    font-weight: bold;
    color: ${customTheme.colors.purple};
  }

  .last-child {
    font-weight: bold;
    color: ${customTheme.colors.gray[300]};
  }
`

const SaveButton = styled.button`
  background: none;
  border: none;
  font-weight: bold;
  color: ${customTheme.colors.purple};
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    font-size: 22px;
    font-weight: bold;
    margin: 0 3px 1px 0;
  }
`
