import React from 'react'
import styled from 'styled-components'
import { FaRegShareSquare } from 'react-icons/fa'
import { MdContentCopy } from 'react-icons/md'
import usersPermissions from '../../../../../utils/mentor-users/user-permission'
import { getUser } from '../../../../utils/storage/user'
import { Link } from 'react-router-dom'
const SessionThree = () => {
  const user = getUser()
  const findPermission = usersPermissions.find((item) => {
    return item.email === user?.user?.email
  })
  return (
    <Container>
      <SubContent>
        <HeaderTitle>Passo 3. Assesment Individual</HeaderTitle>
        <NormalTextGray>Agora vamos entender quais são suas principais fortalezas para podermos falar um pouco sobre autoconhecimento e direcionar a sua mentoria.</NormalTextGray>
        <NormalTextGrayBold>Reserve 60 minutos do seu dia para finalizar seu assessment.</NormalTextGrayBold>
        <NormalText>Seu código de Acesso: {findPermission?.code}  <MdContentCopy
          onClick={() => navigator.clipboard.writeText(findPermission?.code)}
          size={18} color="#364850" style={{ cursor: 'pointer' }}
                                                                  />
        </NormalText>

        <a href="https://my.gallup.com/_Home/RedeemAccessCode" rel="noreferrer" target="_blank" style={{ textDecoration: 'none', width: '270px' }}>
          <ButtonStep>
            <FaRegShareSquare size={20} color="#fff" />
            Responda o  Clifton
          </ButtonStep>
        </a>

        <div style={{ marginTop: '35px' }}>
          <NormalTextGray>Após terminar o Assessment Clifton, você deverá responder uma pesquisa de impacto e potenciais clicando no link abaixo.</NormalTextGray>
          <Link to="/pesquisa-impacto" style={{ textDecoration: 'none' }}>
            <ButtonStep>
              <FaRegShareSquare size={20} color="#fff" />
              Pesquisa de impacto
            </ButtonStep>
          </Link>
        </div>

      </SubContent>
      {/* <NormalTextGray>Você irá preenche-lo e irá receber o resultado no seu email junto com a coach de carreira, só vocês terão acesso a este documento, porém se quiser dividir com mais alguém fique à vontade.
      </NormalTextGray>
      <NormalTextGray> Após finalizar o assessment, por favor marque um horário para a devolutiva com a sua coach.</NormalTextGray>
      <ButtonStep>
        Veja seu relatório
      </ButtonStep> */}
    </Container>
  )
}

export default SessionThree

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
  
`

const HeaderTitle = styled.h4`
  font-size:22.4px;
  font-weight: 400;
  color: #121214;
  line-height: 22.4px;
  font-family: "Playfair Display","Roboto","Open Sans",sans-serif;
`

const NormalText = styled.p`
  font-size: 16px;
  font-weight:500;
  color: #e14b2b;
  line-height: 16px;
  font-family: "Roboto","Open Sans",sans-serif;
  display:flex;
  align-items: center;
  gap:10px;
  `

const NormalTextGray = styled.p`
  font-size: 16px;
  font-weight:400;
  color: #7A7A7A;
  line-height: 24px;
  font-family: "Roboto","Open Sans",sans-serif;`
const NormalTextGrayBold = styled.p`
  font-size: 16px;
  font-weight:500;
  color: #7A7A7A;
  line-height: 24px;
  font-family: "Roboto","Open Sans",sans-serif;
  `

const SubContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:10px;
  margin-top:10px;
`

const ButtonStep = styled.button`
display: flex;
  width: 270px;
  min-width: 270px;
  height: 50px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #e14b2b; 
  border-color: #e14b2b; 
  color: #FFF;
  font-weight: 700;
  font-size: 16px;
  gap: 10px;
`
