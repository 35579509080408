import axios from 'axios'

const basicAuth = 'Basic ZXlKaGJHY2lPaUpJVXpJMU5pSjkuVDJ4cFgydGZVMlZ5ZG1salpWOUZZMjl0YldWeVkyVTZaWGxLYUdKSFkybFBhVXBKVlhwSk1VNXBTamt1VkRCNFNpNW5SV2xmUkU5S2RteFBZV2hmVW5FeGEwRklXbVpsTVU5TGJYSlRlRVJTWlRkSmEwbHZOVzFSUjNWci5XaUI1VXhpel9sSWVjdS1lRmFUTW9uM3BuUWJFcjNmQVJkLWZwRzg5SW5FOmV5SmhiR2NpT2lKSVV6STFOaUo5LlQyeHBYM05mVTJWeWRtbGpaVjlGWTI5dGJXVnlZMlU2WlhsS2FHSkhZMmxQYVVwSlZYcEpNVTVwU2prdVZEQjRTaTVuUldsZlJFOUtkbXhQWVdoZlVuRXhhMEZJV21abE1VOUxiWEpUZUVSU1pUZEphMGx2TlcxUlIzVnIuXzJpNUhBY2tGaDVsc2Z1d0tnRFdCb1V2dEx5clFjOUJQazZrUnVhUlZROA=='

export async function GetLiveZoom () {
  const apiUrl = 'https://api-v2.todasgroup.com.br/api/v1/generate-token'
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: basicAuth }
    })
    return JSON.stringify(response.data)
  } catch (error) {
    console.error('Erro ao validar email:', error)
    return false
  }
}
