export default {
  translations: {
    profile: {
      perfil: 'Profile',
      me: 'About me',
      notes: 'Grades',
      watch: 'Contents',
      todas: 'TODAS GROUP',
      out: 'Logout'
    },
    components: {
      search: 'Search'
    },
    login_screen: {
      email: 'Email',
      password: 'Password',
      forgot_password: 'Forgot your password?',
      message: 'Are you already part of Todas Group and is this your first time on the new platform?',
      update_password: 'Update your password',
      login: 'Login',
      login_load: 'Entering',
      login_microsoft: 'Sign in with Microsoft AD',
      register: 'Do not have an account yet?',
      make_part: 'Be part'
    },
    footer: {
      copyright: 'All rights reserved.',
      home: {
        title: 'SOS Todas Together',
        description: 'Send your question to one of our experts and receive personalized guidance within 48 hours.',
        details: 'Speak to an expert'
      }
    },
    header_logged: {
      welcome: 'Welcome to TODAS',
      home: 'Start',
      habilidades: 'Skills',
      conquistas: 'Achievements'
    },
    conquest_main: {
      parabens: 'Congratulations',
      parabens_desc: 'Your achievements are incredible',
      desc: 'What a fantastic evolution!',
      desc_02: 'We were very proud',
      desc_03: 'Continue to grow by going further and further',
      obj: 'Watch Content',
      obj_02: 'Make Network',
      obj_03: 'See progress'
    },
    conquest_internal: {
      gift: 'See prize',
      shared: 'Shared',
      start: 'Start',
      unblock: 'Unlock new titles and prizes',
      content_watch: 'content watched',
      conquest: 'Current conquest',
      progress: 'I am progressing',
      gift_name: 'Prize',
      title_desc: 'Its constant evolution is very important for the Todas GROUP. With each special badge you earn, you win a prize and further strengthen our entire community of users.',
      award_01: 'Class 01 – Self-esteem for positioning',
      award_02: 'Click here to access your prize'
    },
    watch: {
      watch: 'Watch now',
      watch_now: 'Watch classes now',
      keep_watching: 'Keep watching',
      keep_description: 'Together evolving',
      watch_live: 'Happened live',
      live_description: 'Our uncut and unfiltered mentors'

    },
    skills: {
      trail: 'Skill tracks',
      title_01: 'The science of self-confidence',
      title_02: 'Strategic-positioning',
      title_03: 'High emotional performance',
      title_04: 'Focus and mindfulness',
      title_05: 'Authority and protagonism',
      title_06: 'Adaptability',
      title_07: 'Positive discipline',
      title_08: 'Negotiation and Influence',
      title_09: 'Critical thinking',
      title_10: 'Personal brand',
      title_11: 'Innovation',
      title_12: 'Leadership',
      title_13: 'Networking',
      title_14: 'Strategic communication',
      title_15: 'Motherhood and career',
      title_16: 'Team development',
      details_01: 'Linkedin Top Voice and Business Advisor',
      details_02: 'Diversity Consultant',
      details_03: 'HR Director Diageo and TedXSpeaker',
      details_04: 'Cielo Commercial Director',
      details_05: 'Lifestyle psychiatrist specialist',
      details_06: 'Head of MKT Tik Tok Am. Latina'
    },
    watch_content: {
      watch01: 'Watched',
      watch02: 'In progress',
      watch03: 'Saved',
      watch04: 'No content',
      watch05: 'liked',
      watch06: 'Start watching now',
      watch07: 'Recommended content',
      watch08: 'Description',
      watch09: 'Make Network',
      watch10: 'Create Notes now',
      watch11: 'List Notes',
      watch12: 'Saved',
      watch13: 'Watch later',
      watch14: 'Methodology'
    },
    video_header_title: {
      title01: 'Motherhood and Career',
      title2: 'Disciplina Positiva',
      title3: 'NetWorking',
      title4: 'Desenvolvimento de Equipes',
      title5: 'Comunicação Estratégica'
    },
    video_job_title: {
      title1: 'Co-Fundadora Todas Group',
      title2: 'Fund. B2Mamy',
      title3: 'Neurocientista'
    },
    video_title: {
      title01: 'Joana - Class 01',
      title02: 'What is essential for team development, with the CFO of Gerando Falcões, Julia Machado',
      title03: '“Awareness, preparation and difficult conversations. How to deal with the challenges of team leadership?”, by consultant, mentor and speaker Marisa Salgado',
      title04: 'Self-knowledge and self-confidence to develop teams - Serving yourself before serving others, by consultant, mentor and speaker Marisa Salgado',
      title05: 'Assertive Communication Exercise, with the neuroscientist Drª Carla Tieppo, faculty da Singularity University Brazil',
      title06: 'The role of neuroscience in communication, with the neuroscientist Drª Carla Tieppo, faculty da Singularity University Brazil',
      title07: 'Discipline Exercise with career coach, Lívia Kawano',
      title08: 'Positive discipline and consistent progress over time, with Manoela Mitchell, CEO and Co-founder of Pipo Saúde',
      title09: 'The importance of constancy to build a more disciplined life, with Andrezza Zago, marketing director at BMW Group Financial Services',
      title10: 'Discipline as a way to build self-love, with Andrezza Zago, marketing director at BMW Group Financial Services',
      title11: 'Positive Discipline Exercise',
      title12: 'The power of discipline as a means to solve problems in a lighter way, with neuroscientist Drª Carla Tieppo, faculty at Singularity University Brazil',
      title13: 'From mother to daughter and mother to mother with the Todas mentors',
      title14: 'The importance of the support network, with Marcela Rezende, Vice President of Marketing at MadeiraMadeira',
      title15: 'Maternity and empathy, with Marcela Rezende, Vice President of Marketing at MadeiraMadeira',
      title16: 'Being a happy mother and professional, with Luciana Viegas, creator of the Black Lives with Disabilities Matter Movement',
      title17: 'Motherhood and life transformation, with Luciana Viegas, creator of the Black Lives with Disabilities Matter Movement',
      title18: 'The power of being a mother, with Dhafyni Mendes, Co-founder of Todas Group',
      title19: 'Resolution within maternity, with Dhafyni Mendes, Co-founder of Todas Group',
      title20: 'Plural maternities, with Dani Junco, CEO and Founder of B2Mamy'
    },
    video_config: {
      config01: 'Playback speed',
      config02: 'Options',
      config03: 'Normal'
    },
    description_skills: {
      title: 'Mente',
      title2: 'Modelagem',
      title3: 'Metodologia',
      sub1: 'Comprender cómo reacciona tu cerebro a esta habilidad',
      sub2: 'Aprendiendo de la experiencia de una mujer referente en esta habilidad',
      sub3:
        'Paso a paso para desarrollar esta habilidad en ti mismo'
    },
    home: {
      message: 'Hello World!'
    },
    faq: {
      whatsSpan1: 'Whatsapp support',
      whatsSpan2: 'Start conversation via WhatsApp',
      whatsSpan3: 'Common questions',
      whatsTxT: "Tried to resolve your question in 'Frequently Asked Questions' and didn't find what you were looking for? Get in touch with our support:",
      message: 'No questions registered',
      faq: {
        title_01: 'Common questions',
        title_02: 'Support via WhatsApp'
      }
    },
    PageNotFound: {
      title: 'Page not found',
      p: 'Try our other content'
    }
  }
}
