/* eslint-disable no-console */
import React, { useEffect } from 'react'
import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded'
// import generateToken from './generateToken'
import { getUser } from '../../../utils/storage/user'
import styled from 'styled-components'
import { getZoomLivePermission, setLivePermission } from '../../../utils/storage/mentor'
import { GetLiveZoom } from '../controller'

const ZoomMeeting = () => {
  const user = getUser()
  const client = ZoomMtgEmbedded.createClient()
  const [liveDetails, setLiveDetails] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [liveZoom, setLiveZoom] = React.useState(false)

  const meetingSDKChatElement = document.getElementById('meetingSDKChatElement')

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getDetails = React.useCallback(async () => {
    try {
      const response = await getZoomLivePermission()
      setLiveDetails(JSON.parse(response))
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getDetails()
  }, [getDetails])

  const sdkKey = '4jPChU4iTHuZeO_XGX_YA'
  // const sdkKey = '4BCHB8zXQvyjx1gP7oYz5g'
  // const sdkSecret = 'LCvu0bjSPqhmWXW2RweFo4V1fsik61Wu'
  // const meetingNumber = '8534691070'
  // const passWord = 'YDJ1a9'
  // const role = 0
  const userName = user?.user?.name
  const userEmail = user?.user?.email
  const registrantToken = ''
  const zakToken = ''

  async function getSignature (e) {
    e.preventDefault()

    //  const signature = generateToken(sdkKey, sdkSecret, meetingNumber, role)

    startMeeting(liveDetails?.token)
  }

  function startMeeting (signature) {
    const meetingSDKElement = document.getElementById('meetingSDKElement')

    client.init({
      zoomAppRoot: meetingSDKElement,
      language: 'pt-PT',
      patchJsMedia: true,
      leaveOnPageUnload: true,
      maximumVideosInGalleryView: 3,
      customize: {
        meetingInfo: ['topic', 'host', 'participant', 'dc', 'enctype'],
        video: {
          defaultViewType: 'gallery',
          isResizable: true,
          viewSizes: {
            default: {
              width: windowSize?.width > 600 ? windowSize?.width / 2 : windowSize?.width - 40,
              height: windowSize?.height / 2 - 50
            }
          }
        },
        chat: {
          notificationCls: {
            right: 25,
            top: windowSize?.width < 500 ? windowSize?.height / 6 : windowSize?.height / 4
          },
          popper: {
            disableDraggable: true,
            anchorElement: meetingSDKChatElement,
            placement: windowSize?.width < 500 ? 'bottom' : 'right'
          }
        }
      }
    }).then(() => {
      client.join({
        signature: signature,
        sdkKey: sdkKey,
        meetingNumber: liveDetails?.meetingNumber,
        password: liveDetails?.password,
        userName: userName,
        userEmail: userEmail,
        tk: registrantToken,
        zak: zakToken
      })

        .then((data) => {
          console.log(data, 'RETURN DATA')
          // eslint-disable-next-line no-console
          console.log('joined successfully')
          setLiveZoom(true)
        }).catch(async (error) => {
          console.log(error, 'RETURN error catch 1')
          setLiveZoom(false)
          if (error?.type === 'JOIN_MEETING_FAILED') {
            const response = await GetLiveZoom()
            // eslint-disable-next-line no-console
            console.log(response, 'response')
            //   setLivePermissionTeste(response)
            setLivePermission(response)
            getDetails()
          }

          if (error?.type === 'INVALID_OPERATION') {
            const response = await GetLiveZoom()
            // eslint-disable-next-line no-console
            //  console.log(response, 'response')
            //   setLivePermissionTeste(response)
            setLivePermission(response)
            getDetails()
          }
          // eslint-disable-next-line no-console
          console.log(error)
        })
    }).catch((error) => {
      console.log(error, 'RETURN error catch 2')
      // eslint-disable-next-line no-console
      console.log(error)
    })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <PageWrapper>
      <Container>

        <HeaderTitle>Mentoria ao vivo</HeaderTitle>
        <SubTitle>Como manter o equilíbrio emocional diante de tantos desafios e incertezas no ambiente de trabalho </SubTitle>

        <div id="meetingSDKElement" />
        {liveZoom === false && (
          <StepBtn onClick={getSignature}>Entrar na live</StepBtn>
        )}

      </Container>
    </PageWrapper>
  )
}

export default ZoomMeeting

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-top:10px;
  margin-left:15px;
  gap:20px;
  `

const HeaderTitle = styled.h2`
  font-weight: bold;
  font-size:2.2rem;
  line-height: 1;
  font-family: 'Playfair Display', serif;
  color:#3D1252;
  `
const SubTitle = styled.span`

font-size:1rem;
line-height: 1;
font-family: 'Playfair Display', serif;
color: #828282;
`
const StepBtn = styled.button`
display: flex;
border: none;
width: 170px;
min-width: 90px;
height: 50px;
align-items: center;
justify-content: center;
border-radius: 7px;
cursor: pointer;
background-color: #e14b2b;
color:white;
font-size: 1.0rem;
margin-top: 15px;
`
