import { useTranslation } from 'react-i18next'

const TransLateHook = () => {
  const { t } = useTranslation()

  const TRANSLATE_DESCRIPTION = {
    'Joana - Aula 01': `${t('video_title.title01')}`,
    'O que é essencial para o desenvolvimento de equipes, com a CFO da Gerando Falcões, Julia Machado': `${t('video_title.title02')}`,
    '“Consciência, preparação e conversas difíceis. Como lidar com os desafios da liderança de equipes?”, pela consultora, mentora e palestrante Marisa Salgado': `${t('video_title.title03')}`,
    'Autoconhecimento e autoconfiança para desenvolver times - Atendendo a si mesma antes de atender ao outro, pela consultora, mentora e palestrante Marisa Salgado': `${t('video_title.title04')}`,
    'Exercício de Comunicação Assertiva, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': `${t('video_title.title05')}`,
    'O papel da neurociência na comunicação, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': `${t('video_title.title06')}`,
    'Exercício de Disciplina com a coach de carreira, Lívia Kawano': `${t('video_title.title07')}`,
    'Disciplina positiva e progresso consistente ao longo do tempo, com Manoela Mitchell, CEO e Co-fundadora da Pipo Saúde': `${t('video_title.title08')}`,
    'A importância da constância para construção de uma vida com mais disciplina, com Andrezza Zago, diretora de marketing da BMW Group Financial Services': `${t('video_title.title09')}`,
    'Disciplina como uma forma de construir amor próprio, com Andrezza Zago, diretora de marketing da BMW Group Financial Services': `${t('video_title.title10')}`,
    'Exercício de Disciplina Positiva': `${t('video_title.title11')}`,
    'O poder da disciplina como um meio para resolver problemas de forma mais leve, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': `${t('video_title.title12')}`,
    'De mãe para filha e de mãe para mãe com as mentoras do Todas': `${t('video_title.title13')}`,
    'A importância da rede de apoio, com Marcela Rezende, Vice-Presidente de Marketing da MadeiraMadeira': `${t('video_title.title14')}`,
    'Maternidade e empatia, com Marcela Rezende, Vice-Presidente de Marketing da MadeiraMadeira': `${t('video_title.title15')}`,
    'Ser uma mãe e profissional feliz, com Luciana Viegas, idealizadora do Movimento Vidas Negras Com Deficiência Importam': `${t('video_title.title16')}`,
    'Maternidade e transformação de vida, com Luciana Viegas, idealizadora do Movimento Vidas Negras Com Deficiência Importam': `${t('video_title.title17')}`,
    'A potência de ser mãe, com Dhafyni Mendes, Co-fundadora da Todas Group': `${t('video_title.title18')}`,
    'Resolutividade dentro da maternidade, com Dhafyni Mendes, Co-fundadora da Todas Group': `${t('video_title.title19')}`,
    'Maternidades plurais, com Dani Junco, CEO e Fundadora da B2Mamy': `${t('video_title.title20')}`,
    'Como o nosso cérebro lida com as mudanças ao nosso redor?': t('video_title.title21'),
    'Adaptabilidade cognitiva e emocional com Kim Farrell - Diretora Geral e Marketing TikTok América Latina.': t('video_title.title22'),
    '10 dicas práticas para Adaptabilidade com Kim Farrell - Diretora Geral e Marketing TikTok América Latina.': t('video_title.title23'),
    'Dicas Todas de Adaptabilidade': t('video_title.title24'),
    'A importância da Adaptabilidade atualmente, com Lívia Kawano - Especialista em Desenvolvimento de Carreiras': t('video_title.title25'),
    'Se adaptando a novos ambientes, com Camila Velzi - Head of E-commerce D2C Mondelez Internacional': t('video_title.title26'),
    'Descobrindo novos caminhos e possibilidades com Dilma Campos - CEO e fundadora da Outra Praia (Live Marketing)': t('video_title.title27'),
    'Novos caminhos com Rachel Muller - Diretora Executiva de Negócios da Nestlé.': t('video_title.title28'),
    Introdução: t('video_title.title29'),
    'Autoconfiança - Serotonina e Validação - Aula 1': t('video_title.title30'),
    'Autoconfiança - Propósito e Potência - Aula 2': t('video_title.title31'),
    'Camila Farani - 5 pontos essenciais': t('video_title.title32'),
    'Autoconhecimento - Aula 1': t('video_title.title33'),
    'Autoconhecimento - Aula 2 - Exercício Prático': t('video_title.title34'),
    'Inteligências Múltiplas - Aula 3': t('video_title.title35'),
    'Autoimagem - Aula 4': t('video_title.title36'),
    'Autocrítica - Aula 5': t('video_title.title37'),
    'Autoreconhecimento - Aula 6': t('video_title.title38'),
    'Como nos posicionar assertivamente para atingir os nossos objetivos?': t('video_title.title39'),
    'Desenvolvendo um ciclo virtuoso para o Posicionamento': t('video_title.title40'),
    'Posicionamento na prática com Gabriela Prioli - Apresentadora CNN': t('video_title.title41'),
    'Autoestima para se posicionar, com Vivi Duarte - Diretora de conexões para Meta/Facebook na América Latina': t('video_title.title42'),
    'Planejamento para posicionamento: técnica e métodos para montar seu plano de ação!': t('video_title.title43'),
    'Domínio e Preparação!': t('video_title.title44'),
    'Comunicação não-verbal para posicionamento com Luciana Guimarães - Empreendedora Serial Endeavor': t('video_title.title45'),
    'Comunicação verbal para posicionamento com a Professora de oratória Mª Peroni.': t('video_title.title46'),
    'Protagonismo no posicionamento com Denise Paludetto- Diretora de RH e mentora de líderes, especialista em autogerenciamento de carreira!': t('video_title.title47'),
    'Gerenciamento emocional': t('video_title.title48'),
    'Sistema emocional e a Antecipação': t('video_title.title49'),
    'Equilíbrio emocional com a psiquiatra especialista em estilo de vida Drº Ana Paula Carvalho': t('video_title.title50'),
    'O que é e onde está o bem estar?': t('video_title.title51'),
    'Autogestão de fatores internos': t('video_title.title52'),
    'Roda da Vida': t('video_title.title53'),
    'Estado de Flow': t('video_title.title54'),
    Conclusão: t('video_title.title55'),
    'Aula 01 - Dra Carla Tieppo - Foco e atenção plena': t('video_title.title56'),
    'Aula 02 - Dra Carla Tieppo - Foco e Atenção Plena': t('video_title.title57'),
    'Aula 01 - Foco e Atenção': t('video_title.title58'),
    'Aula 02 - Foco e Atenção': t('video_title.title59'),
    'Aula 01 - Foco e Consciência': t('video_title.title61'),
    'Aula 02 - Gestão de Estresse e Respiração': t('video_title.title62'),
    'Aula 03 - Organização com Atenção Plena': t('video_title.title63'),
    'Aula 04 - Empatia e Comunicação Consciente': t('video_title.title64'),
    'Aula 05 - Vivendo com Atenção Plena': t('video_title.title65'),
    'Meditação 01 - Três respirações': t('video_title.title66'),
    'Meditação 02 - Respiração Consciente': t('video_title.title67'),
    'Meditação 03 - Escaneamento corporal': t('video_title.title68'),
    'Meditação 04 - Gerando conexão com os outros': t('video_title.title69'),
    'Meditação 05 - Gratidão': t('video_title.title70'),
    'Desconstruindo o conceito de Autoralidade com a neurocientista Drª Carla Tieppo': t('video_title.title71'),
    'Dicas de protagonismo com a neurocientista Drª Carla Tieppo': t('video_title.title72'),
    'Buscando referências para a construção da nossa autoralidade com a jornalista Alexandra Loras': t('video_title.title73'),
    'Autoconhecimento para desenvolver Autoralidade e Protagonismo com a jornalista Alexandra Loras': t('video_title.title74'),
    'Como passar pelas adversidades na jornada em busca da autoralidade e protagonismo com a gerente de educação do Grupo Boticário, Liz Camargo': t('video_title.title75'),
    'Teste de Autoralidade': t('video_title.title76'),
    'Entendendo os resultados do Teste de Autoralidade': t('video_title.title77'),
    'Um passo além na Autoralidade com as Dicas do Todas!': t('video_title.title78'),
    '3 Dicas inspiradoras com Mentoras Todas! - Ana Laura Magalhães (Forbes under 30), Flávia Bittencourt (CEO Adidas Brasil) e Suzan Rivetti (Chairman Company Group Johnson & Johnson)': t('video_title.title79'),
    'Ana K. Aula 01': t('video_title.title80'),
    'Ana K. Aula 02': t('video_title.title81'),
    'Construindo uma disciplina mais positiva com pequenos atos, com Andrezza Zago, diretora de marketing da BMW Group Financial Services': t('video_title.title82'),
    'A disciplina positiva na prática, com as mentoras do Todas': t('video_title.title83'),
    'Negociando utilizando a emocionalidade, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': t('video_title.title84'),
    'Exercício de Negociação com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': t('video_title.title85'),
    'Vivências em Negociação com a Diretora Comercial da Cielo, Renata Daltro': t('video_title.title86'),
    'Negociar é para Todas! Dicas de Negociação com as Mentoras do Todas': t('video_title.title87'),
    '4 exemplos de Negociação com a Diretora Executiva da Goldman Sachs, Carolina Ragazzi': t('video_title.title88'),
    'Gerenciamento das emoções e autoconhecimento para negociar com a Diretora Executiva da Goldman Sachs, Carolina Ragazzi': t('video_title.title89'),
    'Negociação com foco em pessoas, com a Gerente Comercial da Suzano S.A, Deidi Souza': t('video_title.title90'),
    'Bate papo sobre Negociação com a Diretora Global do Google Pay, Paula Paschoal e a Fundadora da G2 Capital, Camila Farani': t('video_title.title91'),
    'Pensamento Crítico à luz da neurociência': t('video_title.title92'),
    'Construindo o Pensamento Crítico para uma transformação social com a psicóloga Mafoane Odara': t('video_title.title93'),
    'Diálogos internos para desenvolvimento do Pensamento Crítico com a psicóloga Mafoane Odara': t('video_title.title94'),
    'Métodos de Pensamento Crítico com Gabriela Costa, Senior Director da AB Inbev, em New York': t('video_title.title95'),
    'A importância de se informar para desenvolver o Pensamento Crítico com Alessandra Blanco, diretora geral do Yahoo Brasil': t('video_title.title96'),
    'Exemplos e métodos para o Pensamento Crítico com Alessandra Blanco, diretora geral do Yahoo Brasil': t('video_title.title97'),
    'Dicas para pensar criticamente com base na autoestima com a atriz e ativista Tathi Piancastelli': t('video_title.title98'),
    'Estudos e práticas sobre Pensamento Crítico': t('video_title.title99'),
    'Sessão de desenvolvimento de Pensamento Crítico com a terapeuta Gabriela Sayago': t('video_title.title100'),
    'Trocas comunicativas com pessoas diversas, com a terapeuta Gabriela Sayago': t('video_title.title101'),
    '5 passos para aprimorar seu Pensamento Crítico no ambiente de trabalho com a terapeuta Gabriela Sayago': t('video_title.title102'),
    'Utilizando as percepções alheias ao nosso favor, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': t('video_title.title103'),
    '4 pilares para o desenvolvimento da Marca Pessoal, com a Drª Carla Tieppo, faculty da Singularity University Brazil': t('video_title.title104'),
    'Marca Pessoal, um assunto novo que veio pra ficar! Com Susana Arbex, especialista em Personal Branding': t('video_title.title105'),
    'Dicas práticas sobre Marca Pessoal, com a especialista em Personal Branding, Susana Arbex': t('video_title.title106'),
    'A comunicação como aliada da Marca Pessoal, com Susana Arbex, especialista em Personal Branding': t('video_title.title107'),
    'Teste de Autoanálise para aprimorar sua Marca Pessoal': t('video_title.title108'),
    'Construindo sua Marca Pessoal com as Dicas das Mentoras do Todas': t('video_title.title109'),
    'Marca Pessoal e identidade, com a llíder em Diversidade, Equidade e Inclusão, da Mondelēz International Daniela Sagaz': t('video_title.title110'),
    'Marca Pessoal enquanto legado, com a líder em Diversidade, Equidade e Inclusão, da Mondelēz International Daniela Sagaz': t('video_title.title111'),
    'Vivências e experiências para a construção de uma Marca Pessoal, com Tamara Braga, gerente de Saúde Mental, CEO e Fundadora DiverCidade': t('video_title.title112'),
    'Resultado do Teste de Autoanálise para aprimorar sua Marca Pessoal': t('video_title.title113'),
    'Inovar é para Todas?': t('video_title.title114'),
    'Olhando para o futuro - com a especialista em Inovação Disruptiva Monica Magalhaes': t('video_title.title115'),
    'Principais barreiras para Inovação nas empresas': t('video_title.title116'),
    'Encontrando novas soluções e inovações inesperadas. - com a Co-fundadora do Todas Group Dhafyni Mendes': t('video_title.title117'),
    'Inov(Ação) na prática, com a Co-fundadora do Todas Group Dhafyni Mendes': t('video_title.title118'),
    'Construção de novos futuros, com a Head de Future Beverages da Ambev, Nathália Brandão.': t('video_title.title119'),
    'Métodos para um planejamento estratégico em Inovação, com a Head de Future Beverages da Ambev, Nathália Brandão.': t('video_title.title120'),
    '5 exercícios de Inovação com a Drª Carla Tieppo, Neurocientista': t('video_title.title121'),
    'Estudos e práticas sobre liderança com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': t('video_title.title122'),
    'Teste de Liderança com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': t('video_title.title123'),
    'Perspectivas sobre liderança com a diretora geral da Dior Brasil, Daniela Ota': t('video_title.title124'),
    'Expandindo nossa visão sobre liderança e a importância da liderança feminina com Thiago Coelho, vice-presidente comercial da Iguatemi S.A.': t('video_title.title125'),
    'Enxergando privilégios e mudando cenários com Thiago Coelho, vice-presidente comercial da Iguatemi S.A.': t('video_title.title126'),
    'Traçando perfis de liderança com a coach de líderes e autora, Caroline Marcon': t('video_title.title127'),
    'Inteligência emocional para liderar com a coach de líderes, Caroline Marcon': t('video_title.title128'),
    'Liderança como ferramenta para impulsionar carreiras, com a presidente do Match Group, Eugênia Del Vigna': t('video_title.title129'),
    'Dicas práticas para exercitar nossa capacidade de liderança com a presidente do Match Group, Eugênia Del Vigna': t('video_title.title130'),
    'Relações sociais e como nosso cérebro nos ajuda a fazer Networking': t('video_title.title131'),
    'Conheça a história da Head de marketing da Adobe América Latina, Núbia Mota!': t('video_title.title132'),
    'Dicas e segredos de Núbia Mota para um bom Networking!': t('video_title.title133'),
    'Joana - Aula 02': t('video_title.title134'),
    'Trabalhando de forma estratégica suas possibilidades - Com Tati Oliva, CEO Cross Networking.': t('video_title.title135'),
    'Métodos para se destacar no Networking com a CEO da Cross Networking - Tati Oliva': t('video_title.title136'),
    'Práticas e estudos sobre o Networking': t('video_title.title137'),
    '“Comunicar não é só falar”, com a Líder de Diversidade & Inclusão, Simone Coutinho': t('video_title.title138'),
    'Conectando com o time utilizando uma comunicação assertiva, com a Líder de Diversidade & Inclusão, Simone Coutinho': t('video_title.title139'),
    'Dicas de Comunicação Assertiva com as Mentoras do Todas': t('video_title.title140'),
    'Comunicação assertiva com a ferramenta da Comunicação Não-Violenta, pela Terapeuta Gabriela Sayago': t('video_title.title141'),
    'Teste - Qual é o seu perfil de comunicação?': t('video_title.title142'),
    'A preparação como elemento chave para se comunicar melhor, com a jornalista Mariana Guidolini': t('video_title.title143'),
    'Três dicas práticas para o desenvolvimento de uma boa comunicação e oratória, com a jornalista Mariana Guidolini': t('video_title.title144'),
    'Usando palavras corretas para se comunicar, com com a jornalista Mariana Guidolini': t('video_title.title145'),
    'Construindo autoconfiança para uma comunicação mais assertiva, com jornalista Mariana Guidolini': t('video_title.title146'),
    'Insights sobre a dinâmica do sistema cerebral com a maternidade, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': t('video_title.title147'),
    'Maternidade e as dinâmicas construídas em relação a carreira das mães, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': t('video_title.title148'),
    'Maternidade e ancestralidade, com Gabriela Ferreira, diretora de Diversidade e Inclusão da Serasa Experian': t('video_title.title149'),
    'Desafios da maternidade e carreira, com Gabriela Ferreira, diretora de Diversidade e Inclusão da Serasa Experian': t('video_title.title150'),
    'A importância da escolha das parcerias dentro da maternidade, com Gabriela Ferreira, diretora de Diversidade e Inclusão da Serasa Experian': t('video_title.title151'),
    'Fatos sobre trabalho e maternidade com Dani Junco, CEO e Fundadora da B2mamy': t('video_title.title152'),
    'Super poderes desenvolvidos na maternidade, com Dani Junco, CEO e Fundadora da B2 Mamy': t('video_title.title153'),
    'O que NÃO fazer para o desenvolvimento de equipes, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': t('video_title.title154'),
    'Exercício de fixação para desenvolver equipes, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil': t('video_title.title155'),
    '“Como liderar e se conectar com o seu time?”, com a vice-presidente da bolsa do Brasil, chamada  [B]³, Ana Buchaim': t('video_title.title156'),
    '“Mulheres e diversidade na liderança para melhores resultados”, com a vice-presidente da bolsa do Brasil, chamada  [B]³, Ana Buchaim.': t('video_title.title157'),
    'Dicas de Desenvolvimento de equipes com as Mentoras Todas!': t('video_title.title158'),
    'Pensamento Crítico': t('skills.title_09'),
    'Desenvolvimento de Equipes': t('skills.title_16'),
    Comunicação: t('skills.title_17'),
    'Comunicação Estratégica': t('skills.title_14'),
    'Disciplina Positiva': t('skills.title_07'),
    'Maternidade e Carreira': t('skills.title_15'),
    'Marca Pessoal': t('skills.title_10'),
    'Negociação - Camila Farani e Paula Paschoal': t('skills.title_18'),
    Liderança: t('skills.title_12'),
    Inovação: t('skills.title_11'),
    Networking: t('skills.title_13'),
    Adaptabilidade: t('skills.title_06'),
    'Autoralidade e Protagonismo': t('skills.title_05'),
    'Equilibrio Emocional': t('skills.title_19'),
    'Posicionamento é Presente': t('skills.title_20'),
    'Aprendizados de Empreendedora': t('skills.title_21'),
    'Conselhos de Liderança': t('skills.title_22'),
    'Como ser CEO da sua própria carreira': t('skills.title_23'),
    'Pilares para construir sua marca': t('skills.title_24'),
    'Criando seu espaço': t('skills.title_25'),
    'Escolhas Corajosas': t('skills.title_26'),
    'Identidades e Aprendizados': t('skills.title_27'),
    'Construindo uma carreira além dos padrões': t('skills.title_28'),
    'Mulheres no Topo': t('skills.title_29'),
    'Possibilidades na carreira': t('skills.title_30'),
    'Escolhas que fazem a diferença': t('skills.title_31'),
    'Liderança com autenticidade': t('skills.title_32'),
    'A coragem de se entregar ao novo': t('skills.title_33'),
    'Empreendendo com propósito': t('skills.title_34'),
    'Liderança Humanizada': t('skills.title_35'),
    'Mindset para crescer': t('skills.title_36'),
    'Como criar relevância para sua marca': t('skills.title_37'),
    'Liderando em tempos de crise': t('skills.title_38'),
    'Carreira, Jornada e Olhar do Mundo': t('skills.title_39'),
    '10 aprendizados para se ter sucesso': t('skills.title_40'),
    'Marketing e propósito': t('skills.title_41'),
    'Pontos essenciais em negócios inovadores': t('skills.title_42'),
    'Construindo sua Marca em Tempos de Crise': t('skills.title_43')

  }

  return TRANSLATE_DESCRIPTION
}

export default TransLateHook
